<template>
  <div>
    <div v-for="(forma, i) in opcionesFormaEntrega" :key="i">
      <div
        class="cardCotiDestItemInfoFormasEntregaRow"
        v-if="forma.id == formaPagoSelected || formaPagoSelected == '-1'"
        @click.stop="forma.habil ? formaEntregaSelected(forma.id) : false"
      >
        <div class="cardCotiDestItemInfoFormasEntregaRowCKB">
          <div v-if="forma.habil">
            <img
              :src="iconCheckedR1"
              alt=""
              srcset=""
              v-if="forma.id == formaPagoSelected"
            />
            <img :src="iconUnCheckedR1" alt="" srcset="" v-else />
          </div>
        </div>
        <div
          :class="
            forma.habil
              ? 'cardCotiDestItemInfoFormasEntregaRowText'
              : 'cardCotiDestItemInfoFormasEntregaRowTextNoHabil'
          "
        >
          <div class="cardCotiDestItemInfoFormasEntregaRowText_subrayado">
            {{ forma.formaEntrega }}{{ forma.bancoNombre }}{{ forma.moneda }}
          </div>
          <div
            class="cardCotiDestItemInfoFormasEntregaRowText_simple"
            v-if="forma.habil"
          >
            {{ forma.tipo_cuenta_nombre }}
          </div>
          <div
            class="cardCotiDestItemInfoFormasEntregaRowText_simple"
            v-if="forma.habil"
          >
            {{ forma.numeroCuentaMostrar }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="cardCotiDestNuevoFE"
      @click="call_nueva_forma_entrega"
      v-if="formaPagoSelected == '-1'"
    >
      <div class="cardCotiDestNuevoFEText">
        {{ t("app.cardCotizadorDestinatario.NewFE") }}
      </div>
      <div class="cardCotiDestNuevoFEtBtn" @click="callNewDestinatario">
        <img :src="iconCotiNuevoDestinatario" alt="" srcset="" />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    opcionesFormaEntrega: Array,
    formaPagoSelected: Number,
  },
  data() {
    return {
      iconCheckedR1: require("../../assets/imgs/cotizador/iconCheckedR1.svg"),
      iconUnCheckedR1: require("../../assets/imgs/cotizador/iconUnCheckedR1.svg"),
      iconCotiNuevoDestinatario: require("../../assets/imgs/iconCotiNuevoDestinatario.svg"),
    };
  },
  methods: {
    formaEntregaSelected(index) {
      this.$emit("formaEntregaSelected", {
        destinatarioIndex: this.index,
        formaEntregaIndex: index,
      });
      this.toggleDetalleFormasEntrega = false;
      this.marcarChecked = !this.marcarChecked;
      this.formaSeleccionadaIndex = index;
    },
    call_nueva_forma_entrega() {
      this.$emit("nueva_forma_entrega");
    },
  },
  mounted() {
    // console.log(this.opcionesFormaEntrega);
  },
};
</script>

<style>
.cardCotiDestItemInfoFormasEntregaRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
  min-height: 2rem;
  border-radius: 5px;
  background: #ececec7a;
  padding: 0.3rem 0.4rem 0.3rem 0.6rem;
  cursor: pointer;
}
.cardCotiDestItemInfoFormasEntregaRowText {
  width: 100%;
  padding-left: 0.6rem;
  color: #4c4c4c;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 1px;
  height: 100%;
}
.cardCotiDestItemInfoFormasEntregaRowTextSelected {
  width: 100%;
  color: #4c4c4c;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 1px;
}
.cardCotiDestItemInfoFormasEntregaRowTextNoHabil {
  width: 100%;
  padding-left: 0.6rem;
  color: #4c4c4c;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 8px;
  line-height: 9px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
}
.cardCotiDestItemInfoFormasEntregaRowText_subrayado {
  color: black;
}
.cardCotiDestItemInfoFormasEntregaRowText_simple {
  text-decoration: none !important;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #4c4c4c;
}
.cardCotiDestItemInfoFormasEntregaRowCKB {
  width: 0.7rem;
  height: 100%;
}
.cardCotiDestItemSelect {
  width: 1.25rem;
}
.cardCotiDestNuevoFE {
  margin-top: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.cardCotiDestNuevoFEText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 0.725rem;
  line-height: 0.725rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #614ad3;
  padding-top: 0.2rem;
}
.cardCotiDestNuevoFEtBtn {
  width: 0.9rem;
}
.cardCotiDestContainer_ultimo {
  margin-bottom: 1rem;
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 500px) {
  .cardCotiDestItemInfoFormasEntregaRow {
    padding: 0.4rem 0.5rem 0.4rem 0.7rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowCKB {
    width: 0.8rem;
    height: 100%;
  }
  .cardCotiDestNuevoFEText {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  .cardCotiDestNuevoFEtBtn {
    width: 1.2rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowText {
    font-size: 0.8rem;
    line-height: 0.8625rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowText_simple {
    font-size: 0.7rem;
    line-height: 0.7625rem;
  }
}

/* ////////////////-------------------------------------/////////// */
@media (min-width: 600px) {
  .cardCotiDestItemInfoFormasEntregaRow {
    padding: 0.5rem 0.6rem 0.5rem 0.8rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowCKB {
    width: 0.9rem;
    height: 100%;
  }
  .cardCotiDestNuevoFEText {
    font-size: 1rem;
    line-height: 1.1rem;
  }
  .cardCotiDestNuevoFEtBtn {
    width: 1.4rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowText {
    font-size: 0.9rem;
    line-height: 0.9625rem;
  }
  .cardCotiDestItemInfoFormasEntregaRowText_simple {
    font-size: 0.8rem;
    line-height: 0.8625rem;
  }
}
</style>